import React, { useState, useEffect } from 'react';

import './Posts.css';
import Post from './Post/Post';
import ViewPost from './ViewPost/ViewPost';
import FilterPosts from './FilterPosts/FilterPosts';

const Posts = (props) => {
    const [ resourcesArr, setResourcesArr ] = useState([]);
    const [ immutableResourcesArr, setImmutableResourcesArr ] = useState([]);
    const [ modalResource, setModalResource ] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const api = localStorage.getItem('api');
    const openInNewTab = url => {
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        const win = window.open(`${url}`, '_blank');
        win.focus();
    }
    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(`${api? api : 'https://api.faceblocked.com'}/resource/all`);
                const body = await response.json();
                if(response.ok && body){
                    body.sort((a, b) => {
                        if(a.date < b.date) {
                            return 1;
                        }

                        return -1;
                    })
                    setResourcesArr(body);
                    setImmutableResourcesArr(body);
                    localStorage.setItem('resourcesArr', JSON.stringify(body));
                    const findResource = (resources, id) => {
                        for(let i = 0; i < resources.length; i++){
                            if(resources[i]._id === id){
                                return resources[i];
                            }
                        }
                    }
                    if(props.match.params.post_id){
                        const resource = findResource(body, props.match.params.post_id);
                        if(resource.linkOnly){
                            setModalResource(resource);
                            setModalVisible(true);
                            openInNewTab(resource.blockedLink)
                            
                        } else {
                            setModalResource(resource);
                            setModalVisible(true);
                        }
                    }
                } else {
                    console.log('failure')
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [api, props])
    let posts = null;
    posts = resourcesArr.map(post => <Post shareable={true} post={post} setResourcesArr={setResourcesArr} resourcesArr={resourcesArr} setModalVisible={setModalVisible} setModalResource={setModalResource} key={post._id} {...post} />);
    return( 
        <React.Fragment>
            {modalVisible?
                <ViewPost 
                    resourcesArr={resourcesArr}
                    {...modalResource}
                    title={modalResource.title? modalResource.title : `${modalResource.whoCensored.charAt(0).toUpperCase() + modalResource.whoCensored.slice(1)} censors ${modalResource.subject} ${modalResource.typeOfResource}`}
                    setModalVisible={setModalVisible}
                    setResourcesArr={setResourcesArr}
                />
                :
                null
            }
            <FilterPosts setResourcesArr={setResourcesArr} posts={immutableResourcesArr} />
            {posts}
        </React.Fragment>
    )
}

export default Posts;