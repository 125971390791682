import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { TextInput, TextAreaInput, CheckboxInput, SelectInput, FileInput, DateInput } from '../FormComponents/FormComponents';
import Loading from '../Loading/Loading';
import formatTags from '../../functions/formatTags';
import formatResources from '../../functions/formatResources';
import getResourcesArrays from '../../functions/getResourcesArrays';

const SubmitPost = () => {
    const [ thumbnail, setThumbnail ] = useState(null);
    const [ resources, setResources ] = useState(null);
    const [ resourcePreviews, setResourcePreviews ] = useState([]);
    return (
        <Formik
            initialValues={{
                title: '',
                description: '',
                whoCensored: '',
                typeOfResource: '',
                subject: '',
                blockedLink: '',
                relatedResources: '',
                datePosted: '',
                dateCensored: '',
                linkOnly: false,
            }}
            validationSchema={Yup.object({
                title: Yup.string()
                .max(100, 'Title must be less than 100 characters long')
                .required('Required'),
                description: Yup.string(),
                whoCensored: Yup.string(),
                typeOfResource: Yup.string()
                    .oneOf(['article', 'video', 'picture', 'post', 'website', 'tweet', 'fbpage'], 'Invalid selection')
                    .required('Required'),
                tags: Yup.string()
                    .max(500, 'Tags must not exceed 500 characters'),
                blockedLink: Yup.string(),
                relatedResources: Yup.string(),
                datePosted: Yup.date()
                    .max(new Date(Date.now()), "The date must be today or before"),
                dateCensored: Yup.date()
                    .max(new Date(Date.now()), "The date must be today or before"),
                linkOnly: Yup.boolean()
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const api = localStorage.getItem('api');
                const token = sessionStorage.getItem('token');
                let formData = new FormData();
                setSubmitting(true);
                formData.append('title', values.title)
                formData.append('description', values.description)
                formData.append('whoCensored', values.whoCensored)
                formData.append('typeOfResource', values.typeOfResource)
                if(values.tags){formData.append('tags', JSON.stringify(formatTags(values.tags)))}
                formData.append('blockedLink', values.blockedLink)
                formData.append('relatedResources', JSON.stringify(formatResources(values.relatedResources)))
                formData.append('linkOnly', values.linkOnly)
                if(thumbnail){
                    formData.append('thumbnail', thumbnail)
                }
                if(resources){
                    const keys = Object.keys(resources);
                    for(let i = 0; i < keys.length; i++){
                        formData.append('resources', resources[i]);
                    }
                }
                if(values.datePosted){
                    formData.append('datePosted', new Date(values.datePosted).getTime())
                }
                if(values.dateCensored){
                    formData.append('dateCensored', new Date(values.dateCensored).getTime())
                }
                fetch(`${api}/resource/submit-report`, {
                    method: 'POST',
                    headers: {
                        'Authorization': token
                    },
                    body: formData
                })
                .then(response => {
                    setSubmitting(false);
                    if(response.status === 401){
                        return alert('You must be logged in to post')
                    }
                    //window.location.pathname = '/logged-in'
                })
                .catch(error => {
                    alert(error)
                    setSubmitting(false)
                });
            }}
        >
            {props => (
                <Form>
                    <h1 className="form-title">Report an Instance of Censorship</h1>
                    <CheckboxInput name="linkOnly" style={{border: 'var(--border)', padding: '10px 5px', borderRadius: '3px'}}>
                        This is just a useful link I want to share
                    </CheckboxInput>
                    <TextInput label={props.values.linkOnly? "Text to display" : "Title"} name="title" type="text" autoComplete="off" />
                    {props.values.linkOnly?
                        null
                        :
                        <SelectInput label="Who censored the resource?" name="whoCensored" type="select">
                            <option value=""></option>
                            <option value="facebook">Facebook</option>
                            <option value="twitter">Twitter</option>
                            <option value="youtube">YouTube</option>
                            <option value="google">Google</option>
                        </SelectInput>
                    }
                    <SelectInput label={props.values.linkOnly? "What type of resource is found at the URL?" : "What type of resource was it?"} name="typeOfResource" type="select">
                        <option value=""></option>
                        <option value="article">Article</option>
                        <option value="video">Video</option>
                        <option value="picture">Picture</option>
                        <option value="post">Post</option>
                        <option value="website">Website/URL</option>
                        <option value="tweet">Tweet</option>
                        <option value="fbpage">Facebook Page</option>
                    </SelectInput>
                    <TextInput label={props.values.linkOnly? "Paste the link here" : "Link to BLOCKED post"} name="blockedLink" type="text" />
                    {props.values.linkOnly?
                        null
                        :
                        <>
                            <TextAreaInput label="Link(s) to related resources (separate with commas)" name="relatedResources" type="textarea" style={{height: '35px'}} />
                            <DateInput label="Original post date" name="datePosted" type="date" />
                            <DateInput label="Date post was censored" name="dateCensored" type="date" />
                            <FileInput label="Thumbnail image" name="thumbnail" type="file" id="thumbnail-file" onChange={event => {
                                setThumbnail(event.currentTarget.files[0]);
                                if(event.target.files[0]){
                                    const fileReader = new FileReader();
                                    fileReader.onload = () => {
                                        document.getElementById('thumbnail-preview').src = fileReader.result;
                                    }
                                    fileReader.readAsDataURL(event.target.files[0]);
                                } else {
                                    document.getElementById('thumbnail-preview').src = '/images/thumbnail.jpg';
                                }
                            }} />
                            <img id="thumbnail-preview" src="/images/thumbnail.jpg" alt="" style={{maxWidth: '200px', height: 'auto', marginTop: '5px'}} />
                            <FileInput label="Resource(s)" name="resources" type="file" id="resource-files" multiple onChange={async event => {
                                try {
                                    if(event.target.files){
                                        setResources(event.target.files);
                                        let arrObj = await getResourcesArrays(event.target.files);
                                        setResourcePreviews(arrObj.previews);
                                    } else {
                                        setResourcePreviews([]);
                                        setResources(null);
                                    }
                                } catch (error) {
                                    alert('error adding files')
                                }
                            }} />
                            <span style={{flexDirection: 'row'}}>
                                {resourcePreviews}
                            </span>
                        </>
                    }
                    <TextAreaInput label="Description" name="description" type="textarea" />
                    <TextAreaInput label="Tags (separate with commas)" name="tags" type="textarea" style={{height: '35px'}} />
                    <button className="form-button" type="submit">{props.isSubmitting? <Loading fontSize="20px" /> : 'Submit'}</button>
                </Form>
            )}

        </Formik>
    );
}

export default SubmitPost;