export default tagString => {
    let tagArr = tagString.split(',');
    let cleanedTags = [];
    tagArr.forEach(tag => {
        tag = tag.trim()
        //tag = tag.replace(/[^a-zA-Z0-9 ]/g, '')
        if(tag.length < 1){
            return;
        }
        if(tag.length === 1 && tag.charAt(0) === '#'){
            return;
        }/* 
        if(tag.charAt(0) === '#'){
            cleanedTags.push(tag.slice(1, tag.length));
            return;
        } */
        cleanedTags.push(tag);
    })
    return cleanedTags;
}