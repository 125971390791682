import { useEffect } from 'react';

export default (darkMode) => {
    

    useEffect(() => {
        const api = localStorage.getItem('api');
        if (!api || api !== 'https://api.faceblocked.com') {
            localStorage.setItem('api', 'https://api.faceblocked.com');
        }
        const domain = localStorage.getItem('domain');
        if (!domain || domain !== 'https://faceblocked.com') {
            localStorage.setItem('domain', 'https://faceblocked.com');
        }
        const mediaLocation = localStorage.getItem('mediaLocation');
        if (!mediaLocation || mediaLocation !== 'https://api.faceblocked.com/media') {
            localStorage.setItem('mediaLocation', 'https://api.faceblocked.com/media');
        }
        let cssVars = JSON.parse(localStorage.getItem('cssVars'));
        if (!cssVars) {
            cssVars = {
                light: {
                    backgroundColor: 'white',
                    hoverBackgroundColor: 'rgb(240, 240, 240)',
                    color: 'black',
                    colorSecondary: 'rgb(70, 70, 70)',
                    border: '1px solid rgb(200, 200, 200)',
                    formLabelColor: 'black',
                    modalExitBorder: '2px solid black',
                    linkColor: 'blue',
                    hoverFilter: 'brightness(.5)'
                },
                dark: {
                    backgroundColor: 'black',
                    hoverBackgroundColor: 'rgb(19, 19, 19)',
                    color: 'white',
                    colorSecondary: 'rgb(150, 150, 150)',
                    border: '1px solid rgb(70, 70, 70)',
                    formLabelColor: 'white',
                    modalExitBorder: '2px solid white',
                    linkColor: 'rgb(46, 126, 240)',
                    hoverFilter: 'brightness(1.8)',
                    focusOutline: '2px solid rgb(46, 126, 240)'
                }
            }
            localStorage.setItem('cssVars', JSON.stringify(cssVars));
        }
        if (!darkMode) {
            localStorage.setItem('darkMode', false);
            Object.keys(cssVars.light).forEach(key => {
                document.body.style.setProperty(`--${key}`, cssVars.light[key])
            });
        } else {
            localStorage.setItem('darkMode', true);
            Object.keys(cssVars.dark).forEach(key => {
                document.body.style.setProperty(`--${key}`, cssVars.dark[key])
            });
        }
    }, [darkMode])
};

/* const api = localStorage.getItem('api');
    if (!api || api !== 'https://api.faceblocked.com') {
        localStorage.setItem('api', 'https://api.faceblocked.com');
    }
    const domain = localStorage.getItem('domain');
    if (!domain || domain !== 'https://faceblocked.com') {
        localStorage.setItem('domain', 'https://faceblocked.com');
    }
    const mediaLocation = localStorage.getItem('mediaLocation');
    if (!mediaLocation || mediaLocation !== 'https://api.faceblocked.com/media') {
        localStorage.setItem('mediaLocation', 'https://api.faceblocked.com/media');
    }
    const darkMode = JSON.parse(localStorage.getItem('darkMode'));
    if (!darkMode) {
        localStorage.setItem('darkMode', false);
    }
    let cssVars = JSON.parse(localStorage.getItem('cssVars'));
    if (!cssVars) {
        const cssVarObj = {
            light: {
                backgroundColor: 'white',
                hoverBackgroundColor: 'rgb(240, 240, 240)',
                color: 'black',
                colorSecondary: 'rgb(70, 70, 70)',
                border: '1px solid rgb(200, 200, 200)',
                formLabelColor: 'black',
                modalExitBorder: '2px solid black',
                linkColor: 'blue',
                hoverFilter: 'brightness(.5)'
            },
            dark: {
                backgroundColor: 'black',
                hoverBackgroundColor: 'rgb(19, 19, 19)',
                color: 'white',
                colorSecondary: 'rgb(150, 150, 150)',
                border: '1px solid rgb(70, 70, 70)',
                formLabelColor: 'white',
                modalExitBorder: '2px solid white',
                linkColor: 'rgb(46, 126, 240)',
                hoverFilter: 'brightness(1.8)',
                focusOutline: '2px solid rgb(46, 126, 240)'
            }
        }
        localStorage.setItem('cssVars', JSON.stringify(cssVarObj))
    } */