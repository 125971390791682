import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router,Route } from 'react-router-dom';
import ReactGA from 'react-ga';

import './App.css';
import Header from './components/Header/Header';

import Posts from './components/Posts/Posts';
import SubmitPost from './components/SubmitPost/SubmitPost';
import EditPost from './components/EditPost/EditPost';
import Guidelines from './components/Guidlines/Guidelines';
import Donate from './components/Donate/Donate';
import Account from './components/Account/Account';
import LoggedIn from './components/Account/LoggedIn/LoggedIn';
import SubmitTip from './components/SubmitTip/SubmitTip';
import ViewTips from './components/ViewTips/ViewTips';
//import Footer from './components/Footer/Footer';

import setStorageVars from './customHooks/setStorageVars';

ReactGA.initialize('UA-177197297-1');
const App = () => {
  const [ darkMode, setDarkMode ] = useState(JSON.parse(localStorage.getItem('darkMode')));
  const [ submitTipModalVisible, setSubmitTipModalVisible ] = useState(false);
  setStorageVars(darkMode);

  const trackPage = path => {
    if(path.includes('confirm-account')){
      let path = '/confirm-account';
      ReactGA.set({path});
      ReactGA.pageview(path)
    } else if(path.includes('change-password')){
      let path = '/change-password';
      ReactGA.set({path});
      ReactGA.pageview(path)
    } else {
      ReactGA.set({path});
      ReactGA.pageview(path);
    }
  }
  useEffect(() => {
    trackPage(window.location.pathname)
  })
  return (
    <div className="App">
      <Header darkMode={darkMode} toggleDarkMode={setDarkMode} />
      {/* <div id="top-of-page-ad" >
        AD COMPONENT
      </div> */}
      <Router>
        <Route exact path={[ "/posts/:post_id", "/posts", "/"]} component={Posts} />
        <Route path={[ "/account/:change_password/:token",  "/confirm-account/:confirm_token",  "/account"]} component={Account} />
        <Route path="/logged-in" component={LoggedIn} />
        <Route path="/guidelines" component={Guidelines} />
        <Route path="/donate" component={Donate} />
        <Route path="/submit-post" component={SubmitPost} />
        <Route path="/edit-post" component={EditPost} />
        <Route path="/view-tips" component={ViewTips} />
      </Router>
      {/* <Footer />
      <div id="bottom-of-page-ad" >
        AD COMPONENT
      </div> */}
      {submitTipModalVisible?
        <SubmitTip setModalVisible={setSubmitTipModalVisible} />
        :
        null
      }
      <div id="submit-tip-button" onClick={() => setSubmitTipModalVisible(!submitTipModalVisible)}>Submit a Tip</div>
    </div>
  );
}

export default App;