export default resourceString => {
    let resourceArr = resourceString.split(',');
    let formatedResources = [];
    resourceArr.forEach(resource => {
        resource = resource.trim()
        if(resource.length < 5){
            return;
        }
        formatedResources.push(resource);
    })
    return formatedResources;
}