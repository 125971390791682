import ReactGA from 'react-ga';

export default (posts, filters) => {
    let filterKeys = Object.keys(filters).filter(key => {
        let val = filters[key];
        if(val === '' || val === {} || val === [] || val === null || val === undefined){
            return false;
        }
        return true;
    });

    const filterObj = {};
    filterKeys.forEach(key => {
        filterObj[key] = filters[key]
    });
    ReactGA.event({
        category: 'Posts',
        action: `Filtered Posts`,
        label: `${JSON.stringify(filterObj)}`
    });

    let filteredPosts = [];

    for(let i = 0; i < posts.length; i++){
        let post = posts[i];
        let postValid = true;
        let whyFalse = {
            postVal: null,
            filterVal: null
        }

        for(let c = 0; c < filterKeys.length; c++){
            if(!postValid){
                break;
            }
            let key = filterKeys[c];
            let val = filters[key];
            if(typeof val === 'object' && val.length > 0){
                let postValContainsItem = false;
                for(let count = 0; count < val.length; count++){
                    let item = val[count];
                    if(post[key].includes(item)){
                        postValContainsItem = true;
                    }
                    if(postValContainsItem){
                        break;
                    }
                }
                if(!postValContainsItem){
                    postValid = false;
                    whyFalse.postVal = post[key];
                    whyFalse.filterVal = val;
                }
            } else if(typeof val !== 'object'){
                if(post[key] !== val){
                    postValid = false;
                    whyFalse.postVal = post[key];
                    whyFalse.filterVal = val;
                }
            }
        }

        if(postValid){
            filteredPosts.push(post);
        }
    }
    return filteredPosts;
}