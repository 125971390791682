import { useState, useEffect } from 'react';

export default (breakPoint) => {
    const [ orientation, setOrientation ] = useState(0);

    useEffect(() => {
        window.innerWidth <= breakPoint? setOrientation(0) : setOrientation(1);
        window.addEventListener('resize', () => {
            window.innerWidth <= breakPoint? setOrientation(0) : setOrientation(1);
        });
        return () => {
            window.removeEventListener('resize', () => {
                window.innerWidth <= breakPoint? setOrientation(0) : setOrientation(1);
            });
        }
    }, [orientation, breakPoint]);

    return orientation;
};