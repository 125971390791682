import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';

import { FilterInput } from '../../FormComponents/FormComponents';
import './FilterPosts.css';
import { FilterSVG } from '../../../SVG';
import filter from '../../../functions/filterPosts';

const Tag = props => {
    const [selected, setSelected] = useState(false);

    const handleClick = e => {
        const { tagsState, setTagsState } = props;
        if (selected) {
            let tagsStateMinusTag = [];
            for (let i = 0; i < tagsState.length; i++) {
                if (tagsState[i] !== props.tag) {
                    tagsStateMinusTag.push(tagsState[i])
                }
            }
            props.values.tags = tagsStateMinusTag;
            setTagsState(tagsStateMinusTag);
        } else {
            tagsState.push(props.tag);
            setTagsState(tagsState);
            props.values.tags = tagsState;
        }
        setSelected(!selected);
        props.handleChange(props.values);
    }

    return (
        <span className={selected ? 'tag-button selected-button' : 'tag-button'} onClick={handleClick}>{props.tag}</span>
    )
}

const Tags = props => {
    let uniqueTags = [];
    if(props.posts){
        props.posts.forEach(post => post.tags.forEach(tag => uniqueTags.push(tag)));
    }
    uniqueTags = [...new Set(uniqueTags)];
    const tags = uniqueTags.map(tag => <Tag tag={tag} key={tag} setTagsState={props.setTagsState} tagsState={[...props.tagsState]} {...props} />)
    return (
        <div id="tags-container" className={props.className}>
            {tags}
        </div>
    )
}

const FilterPosts = props => {
    const [hidden, setHidden] = useState(false);
    const [tagsState, setTagsState] = useState([]);
    /* let posts = JSON.parse(localStorage.getItem('resourcesArr'));
    if(!posts){
        posts = props.posts
    } */
    let { posts } = props;
    const handleChange = values => {
        const filteredResources = filter(posts, values);
        props.setResourcesArr(filteredResources);
    }

    useEffect(() => {
        if (window.innerWidth <= 740) {
            setHidden(true);
        } else if(window.innerWidth > 740) {
            setHidden(false);
        }
    }, [])
    return (
        <div id="filter-posts-container">
            <div id="filter-posts-toggle-container">
                <span className="clickable" onClick={() => setHidden(!hidden)}>
                    <FilterSVG fill="grey" style={{ width: '25px', height: '25px' }} />
                    <p>Filter</p>
                </span>
            </div>
            <Formik
                initialValues={{
                    whoCensored: '',
                    typeOfResource: '',
                    tags: tagsState
                }}
                validate={handleChange}
            >
                {props => (
                    <Form id="filter-posts-form" style={hidden? {display: "none"} : null}>
                        <FilterInput className="filter-posts-select" name="whoCensored" type="select">
                            <option value="">Who Censored</option>
                            <option value="facebook">Facebook</option>
                            <option value="twitter">Twitter</option>
                            <option value="youtube">YouTube</option>
                            <option value="google">Google</option>
                        </FilterInput>
                        <FilterInput className="filter-posts-select" name="typeOfResource" type="select">
                            <option value="">Type of Resource</option>
                            <option value="article">Article</option>
                            <option value="video">Video</option>
                            <option value="picture">Picture</option>
                            <option value="post">Post</option>
                            <option value="website">Website/URL</option>
                            <option value="tweet">Tweet</option>
                            <option value="fbpage">Facebook Page</option>
                        </FilterInput>
                        <Tags className={hidden ? 'hidden' : null} setTagsState={setTagsState} tagsState={[...tagsState]} handleChange={handleChange} values={props.values} posts={posts} />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default FilterPosts;