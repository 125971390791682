import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Modal from '../Modal/Modal';

import { TextAreaInput } from '../FormComponents/FormComponents';
import Loading from '../Loading/Loading';

const SubmitTipContent = props => {
    return (
        <Formik
            initialValues={{
                tip: ''
            }}
            validationSchema={Yup.object({
                tip: Yup.string()
                    .max(500, 'Must be less than 500 characters long')
                    .required('Required')
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const api = localStorage.getItem('api');
                const { tip } = values;
                setSubmitting(true);
                fetch(`${api}/tips/submit-tip`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        tip
                    })
                })
                    .then(res => {
                        setSubmitting(false);
                        resetForm();
                        if (res.status !== 200) {
                            alert('something went wrong')
                            return;
                        }
                        alert('We have recieved your tip. Thanks!');
                        props.setModalVisible(false);
                    })
                    .catch(err => console.error(err));
            }}
        >
            {props => (
                <Form style={{ width: 'auto' }}>
                    <h1 className="form-title" style={{ textAlign: 'center' }}>Submit a Tip</h1>
                    <TextAreaInput label="Under 500 characters please!" name="tip" type="textarea" />
                    <button className="form-button" type="submit" onClick={props.handleSubmit}>{props.isSubmitting ? <Loading fontSize="20px" /> : 'Submit'}</button>
                </Form>
            )}
        </Formik>
    )
}

const SubmitTip = props => {
    return (
        <Modal setModalVisible={props.setModalVisible} content={<SubmitTipContent setModalVisible={props.setModalVisible} />} maxWidth="450px" />
    )
}


export default SubmitTip;