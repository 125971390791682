import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactGA from 'react-ga';

import { TextInput } from '../../FormComponents/FormComponents';
import Loading from '../../Loading/Loading';

const PasswordRecover = (props) => {
    return (
        <div style={props.style}>
            <Formik 
                initialValues={{
                    identity: ''
                }}
                validationSchema={Yup.object({
                    identity: Yup.string()
                        .required('Required')
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    const api = localStorage.getItem('api');
                    const { identity } = values;
                    setSubmitting(true);
                    fetch(`${api}/user/reset-password/${identity}`)
                    .then(res => {
                        setSubmitting(false);
                        if(res.status === 200){
                            ReactGA.event({
                                category: 'Account',
                                action: 'Successful request for password reset email'
                            });
                            alert('A password reset email will be sent shortly!');
                        } else {
                            ReactGA.event({
                                category: 'Account',
                                action: 'Failed request for password reset email'
                            });
                            alert('Failure');
                        }
                    })
                    .catch(error => alert(error));
                }}
            >
            {props => (
                <Form>
                    <h1 className="form-title">Reset Password</h1>
                    <TextInput label="Username or Email" name="identity" type="text" />
                    <button className="form-button" type="submit">{props.isSubmitting? <Loading fontSize="20px" /> : 'Submit'}</button>
                </Form>
            )}
            </Formik>
            <div className="form-toggle" onClick={props.switchToLogin}>Remember your password? Click here</div>
            <div className="form-toggle" onClick={props.switchToSignup}>Want to make an account? Click here</div>
        </div>
    )
}

export default PasswordRecover;