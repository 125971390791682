import React, { useState } from 'react';
import ReactGA from 'react-ga';

import './Header.css';
import useOrientation from '../../customHooks/monitorOrientation';

import { 
    AddSVG, 
    ArticleSVG, 
    NoSVG, 
    DarkSVG, 
    LightSVG,
    UserInputSVG
    //MoneySVG
} from '../../SVG';

const Hamburger = (props) => {
    return (
        <div id="hamburger" onClick={props.onClick}>
            <div className="hamburger-bar"></div>
            <div className="hamburger-bar"></div>
            <div className="hamburger-bar"></div>
        </div>
    )
}

const Header = props => {
    const { darkMode } = props;
    let showMenu = false;
    const loggedIn = JSON.parse(sessionStorage.getItem('loggedIn'))
    if(loggedIn){
        if(loggedIn.admin || loggedIn.moderator){
            showMenu = true;
        }
    }
    const orientation = useOrientation(1200);
    const [ menuVisible, setMenuVisible ] = useState(false);
    const iconStyle = {
        width: '18px',
        height: '18px',
        marginRight: '5px',
        position: 'relative',
        top: '3px'
    }
    const changeDarkMode = () => {
        props.toggleDarkMode(!darkMode);
        ReactGA.event({
            category: 'Dark Mode',
            action: 'Toggled Dark Mode',
            label: `Dark Mode: ${!darkMode}`
        });
    }
    return(
        <div id="header-container">
            <img onClick={() => window.location.pathname = ""} id="header-logo" src={darkMode? "/faceblocked-white.png" : "/faceblocked-black.png"} alt=""/>
            {showMenu? 
                <>
                <ul id="header-options" style={!menuVisible && orientation === 0? {display: "none"} : {display: "inline"}}>
                    <li onClick={() => window.location.pathname = '/posts'} className="header-option"><NoSVG style={iconStyle} fill="grey" />Censored Posts</li>
                    <li onClick={() => window.location.pathname = '/submit-post'} className="header-option"><AddSVG style={iconStyle} fill="none" stroke="grey" />Submit a Post</li>
                    {/* <li onClick={() => window.location.pathname = '/guidelines'} className="header-option"><img className="header-option-icon" src="/images/icons/article-icon.svg" alt=""/>View Our Guidelines</li>
                    <li onClick={() => window.location.pathname = '/donate'} className="header-option"><MoneySVG style={iconStyle} fill='grey' />Donate</li> */}
                    <li onClick={() => window.location.pathname = '/account'} className="header-option"><img className="header-option-icon" src="/images/icons/profile-icon.svg" alt=""/>Account</li>
                    <li onClick={() => window.location.pathname = '/logged-in'} className="header-option"><ArticleSVG style={iconStyle} fill="none" stroke='grey' />Review Posts</li>
                    <li onClick={() => window.location.pathname = '/view-tips'} className="header-option"><UserInputSVG style={iconStyle} fill="grey" />View Tips</li>
                    <li onClick={() => props.toggleDarkMode(!darkMode)} className="header-option">{darkMode? <LightSVG style={iconStyle} stroke="grey" fillOpacity={0} /> : <DarkSVG style={iconStyle} stroke="grey" fillOpacity={0} />}{darkMode? 'Light' : 'Dark'}</li>
                </ul>
                <Hamburger onClick={() => setMenuVisible(!menuVisible)} />
                </>
                :
                <li onClick={changeDarkMode} className="header-option no-hover" style={{position: 'absolute', right: '3px', top: '15px', width: 'fit-content', }}>{darkMode? <LightSVG style={iconStyle} stroke="grey" fillOpacity={0} /> : <DarkSVG style={iconStyle} stroke="grey" fillOpacity={0} />}</li>
            }
                
        </div>
    )
}

export default Header;

