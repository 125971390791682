import React, { useState, useEffect } from 'react';
import moment from 'moment';

import './Post.css';

import Interact from '../Interact/Interact';
import Share from '../Interact/Share';

import {
    PictureSVG,
    ArticleSVG,
    VideoSVG,
    WebSVG,
    TweetSVG,
    RedirectSVG,
    FacebookSVG
} from '../../../SVG';

const Title = props => {
    const [ icon, setIcon ] = useState(null);
    useEffect(() => {
        const darkMode = JSON.parse(localStorage.getItem('darkMode'));
        if(props.typeOfResource){
            const { typeOfResource } = props;
            const iconStyle = {
                height: '1.1em',
                width: '1.1em',
                marginRight: '3px'
            }
            if(typeOfResource === 'article'){
                darkMode?
                setIcon(<ArticleSVG style={iconStyle} fill="black" stroke='grey' />)
                :
                setIcon(<ArticleSVG style={iconStyle} fill="white" stroke='grey' />)
            }
            if(typeOfResource === 'video'){
                setIcon(<VideoSVG style={iconStyle} fill='grey' />)
            }
            if(typeOfResource === 'picture'){
                darkMode?
                setIcon(<PictureSVG style={iconStyle} stroke='grey' />)
                :
                setIcon(<PictureSVG style={iconStyle} stroke='grey' fill='white' />)
            }
            if(typeOfResource === 'post'){
                darkMode?
                setIcon(<ArticleSVG style={iconStyle} fill="black" stroke='grey' />)
                :
                setIcon(<ArticleSVG style={iconStyle} fill="white" stroke='grey' />)
            }
            if(typeOfResource === 'website'){
                setIcon(<WebSVG style={iconStyle} fill='none' stroke='grey' strokeWidth='2px' />)
            }
            if(typeOfResource === 'tweet'){
                setIcon(<TweetSVG style={iconStyle} stroke='grey' />)
            }
            if(typeOfResource === 'fbpage'){
                setIcon(<FacebookSVG style={iconStyle} stroke='grey' />)
            }
            if(props.linkOnly){
                setIcon(<RedirectSVG style={iconStyle} fill='none' stroke='grey' />)
            }
        }
    }, [props])
    const title = props.title? props.title : `${props.whoCensored.charAt(0).toUpperCase() + props.whoCensored.slice(1)} censors ${props.subject} ${props.typeOfResource}`
    return (
        <p className='post-title'>
            {icon}
            {props.linkOnly? <span title={props.blockedLink} style={{textDecoration: 'underline', cursor: 'pointer'}}>{title}</span> : title}
        </p>
    )
}

const Post = (props) => {
    const [ shareModal, setShareModal ] = useState(false);
    const date = moment(props.date, 'x').format("MMM. D, YYYY");
    const domain = localStorage.getItem('domain');
    const mediaLocation = localStorage.getItem('mediaLocation');
    const openInNewTab = url => {
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        const win = window.open(`${url}`, '_blank');
        win.focus();
    }
    const setModalAndMakeVisible = () => {
        if(props.linkOnly && props.public){
            openInNewTab(props.blockedLink)
        } else {
            props.setModalResource({...props});
            props.setModalVisible(true);
        }
    }
    const deletePost = (e) => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        
        const api = localStorage.getItem('api');
        const token = sessionStorage.getItem('token');
        const confirmed = window.confirm('Are you sure you want to delete this post?');
        if(!confirmed){
            return;
        }
        fetch(`${api}/resource/${props._id}`, {
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        })
        .then(res => {
            if(res.status !== 200){
                alert('Auth failed...')
            }

            alert("Deleted!");
            window.location.pathname = '/posts';
        })
        .catch(err => console.error(err));
    }
    const goEditPost = e => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        localStorage.setItem('editPost', JSON.stringify({...props}));
        window.location.pathname = '/edit-post';
    }
    const loggedIn = JSON.parse(sessionStorage.getItem('loggedIn')) || { admin: false, moderator: false };
    return (
        <div className="post-container" onClick={setModalAndMakeVisible}>
            {props.thumbnail?
                <div className="post-thumbnail-container">
                    <img className="post-thumbnail" src={`${mediaLocation}/${props.thumbnail}`} alt='' />
                </div>
                :
                null
            }
            
            <Title {...props} />
            <p className="post-details">{date}{props.shareable?
                    <Interact setShareModal={setShareModal} post_id={props._id} setResourcesArr={props.setResourcesArr} resourcesArr={props.resourcesArr} type="shares" count={props.shares} />
                    :
                    null
                }{/* <span className="post-bullet">&#8226;</span>{props.creator_username} */}
            </p>
            <p className="post-details">
                {/* <Interact post_id={props._id} setResourcesArr={props.setResourcesArr} resourcesArr={props.resourcesArr} type="upvotes" count={props.upvotes} />
                <Interact post_id={props._id} setResourcesArr={props.setResourcesArr} resourcesArr={props.resourcesArr} type="downvotes" count={props.downvotes} /> */}
            </p>
            {shareModal?
                <Share url={`${domain}/posts/${props._id}`} setShareModal={setShareModal} {...props} />
                :
                null
            }
            {props.linkOnly?
                loggedIn.admin || loggedIn.moderator?
                    <>
                        <button className="form-button" style={{background: 'red', marginBottom: '0px'}} onClick={deletePost}>Delete this link</button>
                        <button className="form-button" style={{marginBottom: '10px'}} onClick={goEditPost}>Edit this post</button>
                    </>
                    :
                    null
                :
                null
            }
        </div>

    )
}

export default Post;

