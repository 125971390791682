import React from 'react';

import './Interact.css';

const Interact = (props) => {
    const interact = e => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        /*const api = localStorage.getItem('api');
        const domain = localStorage.getItem('domain');
        const token = sessionStorage.getItem('token');

         fetch(`${api}/resource/interact/${props.post_id}/${props.type}?Authorization=${token}`)
        .then(res => {
            if(res.status === 200){
                return res.json()
            }
            return false;
        })
        .then(body => {
            if(body){
                const updatedFields = Object.keys(body);
                const updatedResourceIndex = props.resourcesArr.findIndex(el => el._id === props.post_id);
                const updatedResource = props.resourcesArr[updatedResourceIndex];
                updatedFields.forEach(field => updatedResource[field] += body[field] );
                const resourcesCopy = props.resourcesArr;
                resourcesCopy[updatedResourceIndex] = {...updatedResource};
                props.setResourcesArr([...resourcesCopy]);
                if(props.setModal){
                    props.setModal({visible: true, resource: updatedResource})
                }
            }
        })
        .catch(error => alert(error)); */
        if(props.type === 'shares'){
            props.setShareModal(true);
        }
        /* if(props.type === 'shares'){
            if(navigator.share){
                navigator.share({
                    title: `Checkout this instance of big tech censorship`,
                    url: `${domain}/posts/${props.post_id}`
                })
                .then(() => {
                    console.log('Thanks for sharing')
                })
                .catch(error => console.log(error));
            } else {
                alert(`Share this link: ${domain}/posts/${props.post_id}`)
            }
        } */
    }
    return(
        <span className="interaction-container" onClick={interact}>
            <img className="post-interaction-icon" src={`/images/icons/${props.type}.svg`} alt=""/>
            {`Share`/*props.count*/}
        </span>
    )
}
 
export default Interact;