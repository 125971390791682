import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactGA from 'react-ga';

import { TextInput } from '../../FormComponents/FormComponents';
import Loading from '../../Loading/Loading';

const Signup = (props) => {
    return (
        <div style={props.style}>
            <Formik 
                initialValues={{
                    username: '',
                    email: '',
                    password: '',
                    passwordConfirm: ''
                }}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .max(25)
                        .trim('Cannot have spaces in username')
                        .required('Required'),
                    email: Yup.string()
                        .email('Invalid email')
                        .required('Required'),
                    password: Yup.string()
                        .min(8, 'Password must be 8 characters or more')
                        .max(50, 'Password must be less than 50 characters long')
                        .matches(/[A-Z]/, 'Password must contain at least one capital letter')
                        .matches(/[0-9]/, 'Password must contain at least one number')
                        .matches(/[!@#$&]/, 'Password must contain at least one special character(!@#$&)'),
                    passwordConfirm: Yup.string()
                        .min(8, 'Password must be 8 characters or more')
                        .max(50, 'Password must be less than 50 characters long')
                        .matches(/[A-Z]/, 'Password must contain at least one capital letter')
                        .matches(/[0-9]/, 'Password must contain at least one number')
                        .matches(/[!@#$&]/, 'Password must contain at least one special character(!@#$&)')                       
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    const api = localStorage.getItem('api');
                    const { username, email, password, passwordConfirm } = values;
                    setSubmitting(true);
                    fetch(`${api}/user/signup`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            username,
                            email,
                            password,
                            passwordConfirm
                        })
                    })
                    .then(response => {
                        setSubmitting(false);
                        if(response.status !== 200){
                            ReactGA.event({
                                category: 'Account',
                                action: 'Failed signup attempt'
                            });
                            return alert('Something is wrong with your submission');
                        }
                        ReactGA.event({
                            category: 'Account',
                            action: 'Successful signup'
                        });
                        alert('You will be sent an email to confirm your account shortly!');
                        props.switchToLogin()
                    })
                    .catch(error => alert(error));
                }}
            >
            {props => (
                <Form>
                    <h1 className="form-title">Create an Account</h1>
                    <TextInput label="Username" name="username" type="text" />
                    <TextInput label="Email" name="email" type="email" />
                    <TextInput label="Password" name="password" type="password" />
                    <TextInput label="Confirm Password" name="passwordConfirm" type="password" />
                    <button className="form-button" type="submit">{props.isSubmitting? <Loading fontSize="20px" /> : 'Submit'}</button>
                </Form>
            )}
            </Formik>
            <div className="form-toggle" onClick={props.switchToLogin}>Already have an account? Click here</div>
        </div>
    )
}

export default Signup;

