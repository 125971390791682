import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { TextInput, SelectInput, FileInput, DateInput, TextAreaInput, CheckboxInput } from '../FormComponents/FormComponents';
import Loading from '../Loading/Loading';
import formatTags from '../../functions/formatTags';
import formatResources from '../../functions/formatResources';
import getResourcesArrays from '../../functions/getResourcesArrays';


const EditPost = () => {
    const [ thumbnail, setThumbnail ] = useState(null);
    const [ resources, setResources ] = useState(null);
    const [ resourcePreviews, setResourcePreviews ] = useState([]);
    const editPost = JSON.parse(localStorage.getItem('editPost'));
    const mediaLocation = localStorage.getItem('mediaLocation');

    const deletePost = (e) => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        
        const api = localStorage.getItem('api');
        const token = sessionStorage.getItem('token');
        const confirmed = window.confirm('Are you sure you want to delete this post?');
        if(!confirmed){
            return;
        }
        fetch(`${api}/resource/${editPost._id}`, {
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        })
        .then(res => {
            if(res.status !== 200){
                alert('Auth failed...')
            }

            alert("Deleted!");
            window.location.pathname = '/posts';
        })
        .catch(err => console.error(err));
    }
    
    return (
        <Formik
            initialValues={{
                title: editPost.title,
                description: editPost.description? editPost.description : '',
                whoCensored: editPost.whoCensored,
                typeOfResource: editPost.typeOfResource,
                tags: editPost.tags.toString(),
                blockedLink: editPost.blockedLink,
                relatedResources: editPost.relatedResources.toString(),
                datePosted: editPost.datePosted? moment(editPost.datePosted, 'x').format("YYYY-MM-DD") : '',
                dateCensored: editPost.dateCensored? moment(editPost.dateCensored, 'x').format("YYYY-MM-DD") : '',
                resourceFiles: editPost.resources? editPost.resources : [],
                linkOnly: editPost.linkOnly
            }}
            validationSchema={Yup.object({
                title: Yup.string()
                    .max(100, 'Title must be less than 100 characters long')
                    .required('Required'),
                description: Yup.string(),
                whoCensored: Yup.string(),
                typeOfResource: Yup.string()
                    .oneOf(['article', 'video', 'picture', 'post', 'website', 'tweet', 'fbpage'], 'Invalid selection')
                    .required('Required'),
                tags: Yup.string()
                    .max(500, 'Tags must not exceed 500 characters'),
                blockedLink: Yup.string(),
                relatedResources: Yup.string(),
                datePosted: Yup.date()
                    .max(new Date(Date.now()), "The date must be today or before"),
                dateCensored: Yup.date()
                    .max(new Date(Date.now()), "The date must be today or before"),
                linkOnly: Yup.boolean()
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                const api = localStorage.getItem('api');
                const token = sessionStorage.getItem('token');
                let formData = new FormData();

                setSubmitting(true);
                formData.append('title', values.title)
                formData.append('description', values.description)
                formData.append('whoCensored', values.whoCensored)
                formData.append('typeOfResource', values.typeOfResource)
                if(values.tags){formData.append('tags', JSON.stringify(formatTags(values.tags)))}
                formData.append('blockedLink', values.blockedLink)
                formData.append('relatedResources', JSON.stringify(formatResources(values.relatedResources)))
                formData.append('resourcesArr', values.resourceFiles);
                formData.append('linkOnly', values.linkOnly);
                if(values.datePosted){
                    formData.append('datePosted', new Date(values.datePosted).getTime())
                }
                if(values.dateCensored){
                    formData.append('dateCensored', new Date(values.dateCensored).getTime())
                }
                if(thumbnail){
                    formData.append('thumbnail', thumbnail);
                }
                if(resources){
                    const keys = Object.keys(resources);
                    for(let i = 0; i < keys.length; i++){
                        formData.append('resources', resources[i]);
                    }
                }
                const thumbExt = editPost.thumbnail? editPost.thumbnail.split('.')[1] : null;
                formData.append('thumbFileExt', thumbExt)
                fetch(`${api}/resource/publish-report/${editPost._id}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': token
                    },
                    body: formData
                })
                .then(res => {
                    setSubmitting(false);
                    if(res.status !== 200){
                        alert('something went wrong')
                        return;
                    }
                    window.location.pathname = '/posts'
                })
            }}
        >
            {props => (
                <Form>
                    <h1 className="form-title">Edit Post</h1>
                    <CheckboxInput name="linkOnly" style={{border: 'var(--border)', padding: '10px 5px', borderRadius: '3px'}} checked={props.values.linkOnly}>
                        This is just a useful link I want to share
                    </CheckboxInput>
                    <TextInput label={props.values.linkOnly? 'Display text for link' : "Title"} name="title" type="text" autoComplete="off" />
                    {props.values.linkOnly?
                        null
                        :
                        <SelectInput label="Who censored the resource?" name="whoCensored" type="select">
                            <option value=""></option>
                            <option value="facebook">Facebook</option>
                            <option value="twitter">Twitter</option>
                            <option value="youtube">YouTube</option>
                            <option value="google">Google</option>
                        </SelectInput>
                    }
                    <SelectInput label={props.values.linkOnly? "What type of resource is found at the URL?" : "What type of resource was it?"} name="typeOfResource" type="select">
                        <option value=""></option>
                        <option value="article">Article</option>
                        <option value="video">Video</option>
                        <option value="picture">Picture</option>
                        <option value="post">Post</option>
                        <option value="website">Website/URL</option>
                        <option value="tweet">Tweet</option>
                        <option value="fbpage">Facebook Page</option>
                    </SelectInput>
                    <TextInput label={props.values.linkOnly? 'Paste the link here' : "Link to BLOCKED post"} name="blockedLink" type="text" />
                    {props.values.linkOnly?
                        null
                        :
                        <>
                            <TextAreaInput label="Link(s) to related resources (separate with commas)" name="relatedResources" type="textarea" style={{height: '35px'}} />                    <DateInput label="Original post date" name="datePosted" type="date" />
                            <DateInput label="Date post was censored" name="dateCensored" type="date" />
                            <FileInput label="Thumbnail image(Only add if you wish to change current)" name="thumbnail" type="file" id="thumbnail-file" onChange={event => {
                                setThumbnail(event.currentTarget.files[0]? event.currentTarget.files[0] : null);
                                if(event.target.files[0]){
                                    const fileReader = new FileReader();
                                    fileReader.onload = () => {
                                        document.getElementById('thumbnail-preview').src = fileReader.result;
                                    }
                                    fileReader.readAsDataURL(event.target.files[0]);
                                } else if(editPost.thumbnail){
                                    document.getElementById('thumbnail-preview').src = `${mediaLocation}/${editPost.thumbnail}`
                                } else {
                                    document.getElementById('thumbnail-preview').src = '/images/thumbnail.jpg';
                                }
                            }} />
                            <img id="thumbnail-preview" src={editPost.thumbnail? `${mediaLocation}/${editPost.thumbnail}` : '/images/thumbnail.jpg'} alt="" style={{maxWidth: '200px', height: 'auto', marginTop: '5px'}} />
                            <FileInput label="Resource(s)" name="resources" type="file" id="resource-files" multiple onChange={async event => {
                                try {
                                    if(event.target.files){
                                        setResources(event.target.files);
                                        let arrObj = await getResourcesArrays(event.target.files);
                                        setResourcePreviews(arrObj.previews);
                                    } else {
                                        setResourcePreviews([]);
                                        setResources(null);
                                    }
                                } catch (error) {
                                    alert('error adding files')
                                }
                            }} />
                            <span style={{flexDirection: 'row'}}>
                                {resourcePreviews}
                            </span>
                        </>
                    }
                    <TextAreaInput label="Description" name="description" type="textarea" />
                    <TextAreaInput label="Tags" name="tags" type="textarea" style={{height: '35px'}} />
                    <button className="form-button" type="submit">{props.isSubmitting? <Loading fontSize="20px" /> : 'Publish'}</button>
                    <button className="form-button" style={{background: 'red', marginTop: '25px'}} onClick={deletePost}>Delete!</button>
                    {/* <FileInput label="Resource file" name="resource" type="file" id="resource-file" onChange={event => {
                        setResource(event.currentTarget.files[0]);
                    }}/> */}
                </Form>
            )}

        </Formik>
    );
}

export default EditPost;