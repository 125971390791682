import React, { useState, useEffect } from 'react';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";
import ReactGA from 'react-ga';

import './Share.css';
import Modal from '../../Modal/Modal';

const ShareContent = (props) => {
    const darkMode = JSON.parse(localStorage.getItem('darkMode'));
    const [copied, setCopied] = useState(false);
    const copyLink = () => {
        const el = document.createElement('textarea');
        el.value = props.url;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopied(true);
    }
    return (
        <React.Fragment>
            <p id="share-title">Share Post</p>
            <div style={{ textAlign: 'center' }}>
                <FacebookShareButton url={props.url} className='share-button' children={<FacebookIcon size={32} className='share-icon' round={true} iconFillColor={darkMode ? "black" : 'white'} />} />
                <TwitterShareButton url={props.url} className='share-button' children={<TwitterIcon size={32} className='share-icon' round={true} iconFillColor={darkMode ? "black" : 'white'} />} />
                <EmailShareButton url={props.url} className='share-button' children={<EmailIcon size={32} className='share-icon' round={true} iconFillColor={darkMode ? "black" : 'white'} />} />
                <RedditShareButton url={props.url} className='share-button' children={<RedditIcon size={32} className='share-icon' round={true} iconFillColor={darkMode ? "black" : 'white'} />} />
                <TelegramShareButton url={props.url} className='share-button' children={<TelegramIcon size={32} className='share-icon' round={true} iconFillColor={darkMode ? "black" : 'white'} />} />
                <WhatsappShareButton url={props.url} className='share-button' children={<WhatsappIcon size={32} className='share-icon' round={true} iconFillColor={darkMode ? "black" : 'white'} />} />
            </div>
            <div id="share-link-container">
                <p id="share-link">{props.url}</p>
                <span id="share-copy-link" onClick={copyLink}>{copied ? 'Copied!' : 'Copy'}</span>
            </div>
        </React.Fragment>
    );
}

const Share = props => {
    useEffect(() => {
        ReactGA.event({
            category: 'Posts',
            action: `Shared post: ${props._id}`,
            label: `${props.title}`
        });
    }, [props])
    return(
        <Modal setModalVisible={props.setShareModal} maxWidth='300px' content={<ShareContent {...props} />}  />
    );
}

export default Share;