import React from 'react';

const AddSVG = props => {
    return (
        <svg {...props} style={props.style} fill={props.fill} stroke={props.stroke} strokeWidth='2px' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="_78.add" data-name="78.add"><rect x="1" y="1" width="22" height="22" rx="3" ry="3" /><line x1="12" y1="6" x2="12" y2="18" /><line x1="18" y1="12" x2="6" y2="12" /></g></svg>
    )
}

const ArticleSVG = props => {
    return (
        <svg {...props} style={props.style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={props.fill} stroke={props.stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
    )
}

const NoSVG = props => {
    return (
        <svg {...props} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 426.667 426.667" style={props.style}>
            <path style={props.style} fill={props.fill} d="M213.333,0C95.509,0,0,95.514,0,213.333s95.509,213.333,213.333,213.333
            c117.815,0,213.333-95.514,213.333-213.333S331.149,0,213.333,0z M213.333,372.527c-87.778,0-159.194-71.411-159.194-159.194
            S125.555,54.14,213.333,54.14s159.194,71.415,159.194,159.194S301.111,372.527,213.333,372.527z"/>
            <rect x="17.066" y="186.258" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 213.3327 515.0204)" fill={props.fill} width="392.53" height="54.139" />
        </svg>

    )
}

const DarkSVG = props => {
    return (
        <svg {...props} style={props.style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={props.fill} stroke={props.stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-moon"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>
    )
}

const LightSVG = props => {
    return (
        <svg {...props} style={props.style} fill={props.fill} version="1.1" id="Layer_1_1_" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 16 16">
            <circle cx="8.5" cy="7.5" r="4.5" />
            <rect x="8" width="1" height="2" />
            <rect x="8" y="13" width="1" height="2" />
            <rect x="14" y="7" width="2" height="1" />
            <rect x="1" y="7" width="2" height="1" />
            <rect x="12.596" y="11.096" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.7175 12.8033)" width="1" height="2" />
            <rect x="3.404" y="1.904" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -0.9099 3.6109)" width="1" height="2" />
            <rect x="2.904" y="11.596" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -7.4099 6.3033)" width="2" height="1" />
            <rect x="12.096" y="2.404" transform="matrix(0.7071 -0.7071 0.7071 0.7071 1.7823 10.1107)" width="2" height="1" />
        </svg>
    )
}

const FilterSVG = props => {
    return (
        <svg {...props} version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 32 32" style={props.style} fill={props.fill}>
            <g>
                <path d="M3,8h16.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H29c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2.1c-0.4-1.7-2-3-3.9-3s-3.4,1.3-3.9,3H3
                C2.4,6,2,6.4,2,7S2.4,8,3,8z"/>
                <path d="M29,15H15.9c-0.4-1.7-2-3-3.9-3s-3.4,1.3-3.9,3H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h5.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H29
                c0.6,0,1-0.4,1-1S29.6,15,29,15z"/>
                <path d="M29,24h-2.1c-0.4-1.7-2-3-3.9-3s-3.4,1.3-3.9,3H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h16.1c0.4,1.7,2,3,3.9,3s3.4-1.3,3.9-3H29
                c0.6,0,1-0.4,1-1S29.6,24,29,24z"/>
            </g>
        </svg>
    )
}

const ListSVG = props => {
    return (
        <svg {...props} style={props.style} viewBox="0 0 19 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g stroke={props.stroke} strokeWidth="1" fill="none" fill-rule="evenodd">
                <g id="Outlined" transform="translate(-850.000000, -2062.000000)">
                    <g id="Editor" transform="translate(100.000000, 1960.000000)">
                        <g id="Outlined-/-Editor-/-format_list_bulleted" transform="translate(748.000000, 98.000000)">
                            <g>
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M4,10.5 C3.17,10.5 2.5,11.17 2.5,12 C2.5,12.83 3.17,13.5 4,13.5 C4.83,13.5 5.5,12.83 5.5,12 C5.5,11.17 4.83,10.5 4,10.5 Z M4,4.5 C3.17,4.5 2.5,5.17 2.5,6 C2.5,6.83 3.17,7.5 4,7.5 C4.83,7.5 5.5,6.83 5.5,6 C5.5,5.17 4.83,4.5 4,4.5 Z M4,16.5 C3.17,16.5 2.5,17.18 2.5,18 C2.5,18.82 3.18,19.5 4,19.5 C4.82,19.5 5.5,18.82 5.5,18 C5.5,17.18 4.83,16.5 4,16.5 Z M7,19 L21,19 L21,17 L7,17 L7,19 Z M7,13 L21,13 L21,11 L7,11 L7,13 Z M7,5 L7,7 L21,7 L21,5 L7,5 Z" id="🔹-Icon-Color"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

const MoneySVG = props => {
    return (
        <svg {...props} style={props.style} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 82.9 76.3" enable-background="new 0 0 82.9 76.3" space="preserve">
            <g>
                <path fill={props.fill} d="M64.6,15.4c-4.1,2.4-7.1,3.8-9.4,3.4c2.5-1.1,6.9-3.4,9.3-8.8c0.2-0.4-1.1-1-1.5-1.2
		c-0.4-0.2-0.9,0-1.1,0.4c-1.8,4.2-4.2,6.4-6.3,7.5c2.4-4.4,3.8-10.4,3.8-15H41.3H22.4c0,5.8,2.4,14.2,6.1,18.2h24.8c0,0,0,0,0,0
		c1,0.3,2.2,0.5,3.7,0.5c2.4,0,5.4-0.6,8.8-2.6c0.4-0.2,0.1-1.9-0.1-2.3C65.5,15.3,65,15.2,64.6,15.4z"/>
                <path fill={props.fill} d="M53.6,21.9h-0.2H28.4h-0.2C15.5,27,6.4,39.4,6.4,53.9c0,19,15.4,20.7,34.5,20.7c19,0,34.5-1.6,34.5-20.7
		C75.4,39.4,66.3,27,53.6,21.9z M50.3,53.1c-0.4,4.2-3.9,6.7-7.1,7l0,4.4H39v-4.1c0,0-4.8-0.3-7.6-2l1.6-5c0,0,1.9,1,4.1,1.5
		c2.2,0.5,6.1,0.3,6.6-1.8c0.5-2-0.9-2.8-3.6-3.6c-2.7-0.8-7.5-3-8-6.7c-0.5-4,1.4-7.2,6.8-8.3v-4.3h4.3v3.7c0,0,4.4,0.3,6,1.4
		l-1,4.6c0,0-4.1-1.4-6.9-1.2c-2.8,0.2-3,3.1-1,4.3c2.4,1.5,5.8,2.6,7,3.2C48.3,47,50.7,49,50.3,53.1z"/>
            </g>
        </svg>
    )
}

const PictureSVG = props => {
    return (
        <svg {...props} style={props.style} fill={props.fill} stroke={props.stroke} strokeWidth='2px' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g ><rect className="cls-1" x="1" y="1" width="22" height="22" rx="3" ry="3" /><polyline className="cls-1" points="2 22 11 13 21 23" /><polyline className="cls-1" points="15 17 19 13 23 17" /><circle className="cls-1" cx="14" cy="7" r="2" /></g></svg>
    )
}

const VideoSVG = props => {
    return (
        <svg {...props} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Outlined" transform="translate(-170.000000, -952.000000)">
                    <g id="Av" transform="translate(100.000000, 852.000000)">
                        <g id="Outlined-/-AV-/-play_circle_filled_white" transform="translate(68.000000, 98.000000)">
                            <g>
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z M10,16.5 L16,12 L10,7.5 L10,16.5 Z" id="🔹-Icon-Color" fill={props.fill}></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

const WebSVG = props => {
    return (
        <svg {...props} version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 32 32" space="preserve">
            <circle className="st1" cx="16" cy="16" r="13" />
            <ellipse className="st1" cx="16" cy="16" rx="6" ry="13" />
            <line className="st1" x1="3" y1="16" x2="29" y2="16" />
        </svg>
    )
}

const TweetSVG = props => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
        </svg>
    )
}

const NavArrowSVG = props => {
    return (
        <svg {...props} fill="none" viewBox="0 0 11 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g id="Icons">
                <g id="Rounded" transform="translate(-548.000000, -3434.000000)">
                    <g id="Navigation" transform="translate(100.000000, 3378.000000)">
                        <g id="-Round-/-Navigation-/-arrow_back_ios" transform="translate(442.000000, 54.000000)">
                            <g>
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M16.62,2.99 C16.13,2.5 15.34,2.5 14.85,2.99 L6.54,11.3 C6.15,11.69 6.15,12.32 6.54,12.71 L14.85,21.02 C15.34,21.51 16.13,21.51 16.62,21.02 C17.11,20.53 17.11,19.74 16.62,19.25 L9.38,12 L16.63,4.75 C17.11,4.27 17.11,3.47 16.62,2.99 Z" id="🔹-Icon-Color" fill={props.fill}></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

const UserInputSVG = props => {
    return (
        <svg {...props} viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Outlined" transform="translate(-645.000000, -420.000000)">
                    <g id="Action" transform="translate(100.000000, 100.000000)">
                        <g id="Outlined-/-Action-/-record_voice_over" transform="translate(544.000000, 318.000000)">
                            <g>
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M9,13 C11.21,13 13,11.21 13,9 C13,6.79 11.21,5 9,5 C6.79,5 5,6.79 5,9 C5,11.21 6.79,13 9,13 Z M9,7 C10.1,7 11,7.9 11,9 C11,10.1 10.1,11 9,11 C7.9,11 7,10.1 7,9 C7,7.9 7.9,7 9,7 Z M9,15 C6.33,15 1,16.34 1,19 L1,21 L17,21 L17,19 C17,16.34 11.67,15 9,15 Z M3,19 C3.22,18.28 6.31,17 9,17 C11.7,17 14.8,18.29 15,19 L3,19 Z M15.08,7.05 C15.92,8.23 15.92,9.76 15.08,10.94 L16.76,12.63 C18.78,10.61 18.78,7.56 16.76,5.36 L15.08,7.05 Z M20.07,2 L18.44,3.63 C21.21,6.65 21.21,11.19 18.44,14.37 L20.07,16 C23.97,12.11 23.98,6.05 20.07,2 Z" id="🔹-Icon-Color" fill={props.fill}></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

const RedirectSVG = props => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 24 24" fill="none" stroke={props.stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
            <polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>
        </svg>
    )
}

const FacebookSVG = props => {
    return (
        <svg {...props} version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" space="preserve">
            <path style={{fill: 'none', stroke: `${props.stroke}`, strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '10'}} d="M23,31H9c-4.4,0-8-3.6-8-8V9c0-4.4,3.6-8,8-8h14c4.4,0,8,3.6,8,8v14C31,27.4,27.4,31,23,31z"/>
            <path style={{fill: 'none', stroke: `${props.stroke}`, strokeWidth: '2', strokeLinecap: 'round', strokeLinejoin: 'round', strokeMiterlimit: '10'}} d="M26,16h-5v-2.8c0-0.7,0.5-1.2,1.2-1.2H25V7h-4h0c-2.8,0-5,2.2-5,5v0v4h-4v5h4v10h5V21h3L26,16z"/>
        </svg>
    )
}

export {
    AddSVG,
    ArticleSVG,
    NoSVG,
    DarkSVG,
    LightSVG,
    FilterSVG,
    ListSVG,
    MoneySVG,
    PictureSVG,
    VideoSVG,
    WebSVG,
    TweetSVG,
    NavArrowSVG,
    UserInputSVG,
    RedirectSVG,
    FacebookSVG
}