import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { NumberInput, SelectInput } from '../FormComponents/FormComponents';
import './Donate.css';


const DonateCustom = props => {
    return(
        <Formik 
            initialValues={{
                amount: '',
                frequency: ''
            }}
            validationSchema={Yup.object({
                amount: Yup.number()
                    .min(1)
                    .required('Required'),
                frequency: Yup.string()
                    .oneOf(['monthly', 'once'], 'Invalid selection')
                    .required('Required')
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    resetForm();
                    setSubmitting(false);
                }, 100);
            }}
        >
        {props => (
            <Form>
                <h2 className="form-title">Make a Custom Donation</h2>
                <NumberInput label="Amount" name="amount" type="number" placeholder="$ USD" />
                <SelectInput label="How often do you want to donate?" name="frequency" type="select">
                    <option value="">Select one</option>
                    <option value="monthly">Monthly</option>
                    <option value="once">Once</option>
                </SelectInput>
                <button className="form-button" type="submit">{props.isSubmitting? '...loading' : 'Submit'}</button>
            </Form>
        )}
        </Formik>
    )
}

const DonateOption = props => {
    return(
        <div className="donate-option" onClick={() => alert("If there was a back-end you would be directed to a secure payment page.")}>
            <p className="donate-option-monthly">Monthly donation of</p>
            <p className="donate-amount">${props.dollars}</p>
        </div>
    )
}

const Donate = () => {
    const [ customAmount, setCustomAmount ] = useState(100);
    return (  
        <div id="donate-container">
            <h1 id="donate-title">Support Combatting Internet Censorship</h1>
            <DonateOption dollars={5} />
            <DonateOption dollars={20} />
            <DonateOption dollars={50} />
            <DonateCustom customAmount={customAmount} setCustomAmount={setCustomAmount} />
        </div>
    );
}
 
export default Donate;