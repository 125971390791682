import React from 'react';

export default async fileObj => {
    const makeResourcesArr = (filesObj) => {
        const keys = Object.keys(filesObj);
        return keys.map(key => filesObj[key]);
    }

    const readFileAsync = file => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }
    
    const makeResourcePreviewsArr = async function(filesObj){
        try {
            let previewArr = [];
            const keys = Object.keys(filesObj); 
            for(const key of keys){
                let dataURL = await readFileAsync(filesObj[key]);
                let el;
                if(dataURL.includes('image')){
                    el = <img key={dataURL} src={dataURL} alt="" style={{maxWidth: '80px', height: 'auto', marginTop: '5px', marginRight: '5px', display: 'inline-block'}} />
                }
                if(dataURL.includes('video')){
                    el = <video controls key={dataURL} src={dataURL} alt="" style={{maxWidth: '200px', height: 'auto', marginTop: '5px', marginRight: '5px', display: 'inline-block'}} />
                }
                previewArr.push(el)
            }
            return previewArr;
        } catch (error) {
            alert('Error adding files...')
        }
    }

    let files = makeResourcesArr(fileObj);
    let previews = await makeResourcePreviewsArr(fileObj)

    return { files, previews };
}