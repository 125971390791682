import React, { useState, useEffect } from 'react';

import './Account.css';
import Signup from './Signup/Signup';
import Login from './Login/Login';
import Logout from './Logout/Logout';
import PasswordRecover from './PasswordRecover/PasswordRecover';
import ChangePassword from './ChangePassword/ChangePassword';
import ConfirmAccount from './ConfirmAccount/ConfirmAccount';

const Account = props => {
    const [ whichForm, setWhichForm ] = useState('login');
    const [ confirmAccount, setConfirmAccount ] = useState(false);
    const loggedIn = sessionStorage.getItem('loggedIn');
    useEffect(() => {
        if(loggedIn){
            setWhichForm('logout'); 
        }
        if(props.match.params.change_password === 'change-password'){
            setWhichForm('changePassword')
        }
        if(props.match.params.confirm_token){
            setConfirmAccount(true);
        }
    }, [loggedIn, props])
    return( 
        <div id="account">
            {whichForm === 'login'?
                <Login 
                    switchToSignup={() => setWhichForm('signup')}
                    switchToPasswordRecover={() => setWhichForm('passwordRecover')}
                />
                :
                null
            }
            {whichForm === 'signup'?
                <Signup 
                    switchToLogin={() => setWhichForm('login')}
                    switchToPasswordRecover={() => setWhichForm('passwordRecover')}
                />
                :
                null
            }
            {confirmAccount?
                <ConfirmAccount 
                    setConfirmAccount={setConfirmAccount}
                    token={props.match.params.confirm_token}
                />
                :
                null
            }
            {whichForm === 'passwordRecover'?
                <PasswordRecover 
                    switchToLogin={() => setWhichForm('login')} 
                    switchToSignup={() => setWhichForm('signup')}
                />
                :
                null
            }
            {whichForm === 'changePassword'?
                <ChangePassword 
                    changeToken={props.match.params.token}
                    switchToLogin={() => setWhichForm('login')} 
                />
                :
                null
            }
            {whichForm === 'logout'?
                <Logout 
                    switchToLogin={() => setWhichForm('login')}
                />
                :
                null
            }
        </div>
    )
}

export default Account;