import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import Modal from '../../Modal/Modal';
import Loading from '../../Loading/Loading';

const ConfirmAccountContent = props => {
    const [ loading, setLoading ] = useState(true);
    const [ confirmed, setConfirmed ] = useState(false);
    const api = localStorage.getItem('api');
    useEffect(() => {
        fetch(`${api}/user/confirm-account`, {
            method: 'GET',
            headers: {
                'Authorization': props.token
            }
        })
        .then(res => {
            setLoading(false);
            if(res.status === 200){
                setConfirmed(true);
                ReactGA.event({
                    category: 'Account',
                    action: 'Confirmed Account'
                });
            }
        })
        .catch(err => setLoading(false))
    }, [loading, confirmed, api, props])
    return (
        <React.Fragment>
            <h1 style={{textAlign: 'center'}}>Confirm Account</h1>
            {loading?
                <Loading fontSize='30px' />
                :
                confirmed?
                    <p>Account confirmed! You can now login.</p>
                    :
                    <p>Error confirming account. Please try again later!</p>
            }
        </React.Fragment>
    )
}

const ConfirmAccount = props => {
    return (  
        <Modal setModalVisible={props.setConfirmAccount} maxWidth="300px" content={<ConfirmAccountContent {...props} />} style={props.style} />
    );
}
 
export default ConfirmAccount;