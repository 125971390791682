import React from 'react';

import './Modal.css';

const Modal = props => {

    const preventBubble = e => {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const hide = e => {
        if(e){
            preventBubble(e);
        }
        props.setModalVisible(false);
    }
    return (  
        <div id="modal-overlay" onClick={e => hide(e)} style={props.style}>
            <div id="modal-body" onClick={e => preventBubble(e)} style={{maxWidth: props.maxWidth}}>
                <div id="modal-exit" onClick={(e) => hide(e)}></div>
                {props.content}
            </div>
        </div>
    );
}
 
export default Modal;