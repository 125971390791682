import React from 'react';

const Logout = (props) => {
    const logout = () => {
        sessionStorage.removeItem('loggedIn');
        sessionStorage.removeItem('token');
        props.switchToLogin()
    }
    return (  
        <div style={props.style} >
            <h1 style={{textAlign: "center", marginBottom: '20px'}}>My Account</h1>
            <button className="form-button" onClick={logout}>Logout</button>
        </div>
    );
}
 
export default Logout;