import React, { useState, useEffect } from 'react';

import './LoggedIn.css';
import Post from '../../Posts/Post/Post';
import ViewPost from '../../Posts/ViewPost/ViewPost';
import Loading from '../../Loading/Loading';

const LoggedIn = (props) => {
    const [postList, setPostList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalResource, setModalResource] = useState(null);
    const [loading, setLoading] = useState(true);
    const loggedIn = JSON.parse(sessionStorage.getItem('loggedIn'));
    const token = sessionStorage.getItem('token');
    const api = localStorage.getItem('api');
    useEffect(() => {
        (async () => {
            const checkTokenRes = await fetch(`${api}/check-token`, {
                headers: {
                    'Authorization': token
                }
            });
            if (checkTokenRes.status !== 200) {
                window.location.pathname = '/account';
                sessionStorage.removeItem('loggedIn');
                sessionStorage.removeItem('token');
            }
            const getPosts = await fetch(`${api}/resource/all`, {
                headers: {
                    'Authorization': token
                }
            });
            const getPostsBody = await getPosts.json();
            if(getPostsBody){
                setLoading(false);
            }
            setPostList(getPostsBody);
        })()
    }, [api, token])

    let posts = [];
    postList.forEach(post => post.public === false ? posts.push(<Post {...post} shareable={false} edit={true} setModalVisible={setModalVisible} setModalResource={setModalResource} resourcesArr={postList} key={post._id} />) : null);
    return (
        <div id="logged-in-container">
            {loggedIn ?
                <>
                    <p id="logged-in-title">Hello {loggedIn.username}! {posts.length >= 1? `Below are some posts that haven't been made public yet. Review/Edit/Post them!` : `There are currently no posts to be reviewed.`}</p>
                    <div id="logged-in-posts">
                        {loading? 
                            <Loading fontSize="20px" />
                            :
                            null
                        }
                        {posts}
                        {modalVisible ?
                            <ViewPost
                                resourcesArr={postList}
                                {...modalResource}
                                post={modalResource}
                                title={modalResource.title? modalResource.title : `${modalResource.whoCensored.charAt(0).toUpperCase() + modalResource.whoCensored.slice(1)} censors ${modalResource.subject} ${modalResource.typeOfResource}`}
                                setModalVisible={setModalVisible}
                                shareable={false}
                            />
                            :
                            null
                        }
                    </div>
                </>
                :
                null
            }

        </div>
    );
}

export default LoggedIn;