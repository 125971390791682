import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ReactGA from 'react-ga';
import Carousel from 'react-elastic-carousel';

import './ViewPost.css';

import Interact from '../Interact/Interact';
import Share from '../Interact/Share';
import Modal from '../../Modal/Modal';
import { NavArrowSVG } from '../../../SVG';

const GalleryArrow = props => {
    const style = {
        borderRadius: '100px', 
        top: '50%', 
        position: 'relative',
        transform: `${props.type === 'NEXT'? 'rotate(180deg)' : null}`,
        cursor: 'pointer'
    }
    return(
        <div onClick={props.onClick}>
            <NavArrowSVG width="20px" height='20px' fill="grey" style={style} />
        </div>
    )
}

const GalleryTab = props => {
    let tabs = [];
    for(let i = 0; i < props.pages.length; i++){
        if(props.activePage === i){
            tabs.push(
                <div key={i} className='gallery-tab gallery-tab-active' />
            )
        } else {
            tabs.push(
                <div key={i} className='gallery-tab' />
            )
        }
    }
    return (
        <span id='gallery-tabs-container'>
            {tabs}
        </span>
    )
}

const ViewPostContent = props => {
    const [img, setImg] = useState(null);
    const [galleryItems, setGalleryItems] = useState(null);
    const [galleryHeight, setGalleryHeight] = useState(null);
    const loggedIn = JSON.parse(sessionStorage.getItem('loggedIn')) || { admin: false, moderator: false };
    let relatedLinks = [];
    if(props.relatedResources.length > 0){
        relatedLinks = props.relatedResources.map(resource => <div className="view-post-link" onClick={() => openInNewTab(resource)} key={resource}>{resource}</div>)
    }

    useEffect(() => {
        const mediaLocation = localStorage.getItem('mediaLocation');
        if (props.resources) {
            let galleryItemsArr = props.resources.map((resource, index) => {
                switch(true){
                    case resource.toLowerCase().includes('jpg'): return <img id={`media-${index}`} key={resource} className="view-post-media" src={`https://api.faceblocked.com/media/${resource}`} alt='' />;
                    case resource.toLowerCase().includes('png'): return <img id={`media-${index}`} key={resource} className="view-post-media" src={`https://api.faceblocked.com/media/${resource}`} alt='' />;
                    case resource.toLowerCase().includes('svg'): return <img id={`media-${index}`} key={resource} className="view-post-media" src={`https://api.faceblocked.com/media/${resource}`} alt='' />;
                    case resource.toLowerCase().includes('gif'): return <img id={`media-${index}`} key={resource} className="view-post-media" src={`https://api.faceblocked.com/media/${resource}`} alt='' />;
                    default: return <video id={`media-${index}`} key={resource} className="view-post-media" src={`https://api.faceblocked.com/media/${resource}`} controls />
                }
            });
            setGalleryItems(galleryItemsArr);
        } else if (props.thumbnail) {
            setImg(`${mediaLocation}/${props.thumbnail}`)
        }
    }, [props])

    const preventBubble = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }
    const goEditPost = e => {
        preventBubble(e);
        localStorage.setItem('editPost', JSON.stringify(props.post));
        window.location.pathname = '/edit-post';
    }

    const openInNewTab = url => {
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        const win = window.open(`${url}`, '_blank');
        win.focus();
    }

    const modifyGalleryHeight = props => {
        let height = document.getElementById(props.item.id).offsetHeight;
        setGalleryHeight(height + 20);
    }

    return (
        <React.Fragment>
            {img ?
                <img className="view-post-media" src={img} alt="" />
                :
                null
            }
            {galleryItems?
                galleryItems.length > 1?
                    <Carousel renderArrow={GalleryArrow} renderPagination={GalleryTab} onChange={(props) => modifyGalleryHeight(props)} style={galleryHeight? {height: `${galleryHeight}px`}: null} >
                        {galleryItems}
                    </Carousel>
                    :
                    <>
                        {galleryItems}
                    </>
                :
                null
            }
            {props.linkOnly && !props.public?
                <p style={{marginBottom: '15px'}}>* once public this post will open a new tab with the link, not open a modal window. The title will be the display text and will hyperlink to the specified URL.</p>
                :
                null
            }
            <h1 id="view-post-title">{props.title}</h1>
            {/* <Interact post_id={props._id} setResourcesArr={props.setResourcesArr} resourcesArr={props.resourcesArr} setModal={props.setModal} type="upvotes" count={props.upvotes} />
                <Interact post_id={props._id} setResourcesArr={props.setResourcesArr} resourcesArr={props.resourcesArr} setModal={props.setModal} type="downvotes" count={props.downvotes} />*/}
            {props.shareable ?
                <Interact setShareModal={props.setShareModalVisible} post_id={props._id} setResourcesArr={props.setResourcesArr} resourcesArr={props.resourcesArr} setModal={props.setModal} type="shares" count={props.shares} />
                :
                null
            }
            {props.blockedLink ?
                props.linkOnly?
                    <>
                        <p className="view-post-sub-title">{props.public? 'Link to view in case your browser blocked a popup' : 'Link that users will be redirected to:'}</p>
                        <div className="view-post-link" onClick={() => openInNewTab(props.blockedLink)}>{props.blockedLink}</div>
                    </>
                    :
                    <>
                        <p className="view-post-sub-title">Go to blocked post:</p>
                        <div className="view-post-link" onClick={() => openInNewTab(props.blockedLink)}>{props.blockedLink}</div>
                    </>
                : 
                null
            }
            {relatedLinks.length > 0 ?
                <>
                    <p className="view-post-sub-title">Related resource{relatedLinks.length > 1? 's' : null}:</p>
                    {relatedLinks}
                </>
                :
                null
            }
            {props.datePosted ?
                <>
                    <p className="view-post-sub-title">Date posted:</p>
                    <div className="view-post-date">{moment(props.datePosted, 'x').format("MMM D, YYYY")}</div>
                </>
                :
                null
            }
            {props.dateCensored ?
                <>
                    <p className="view-post-sub-title">Date censored:</p>
                    <div className="view-post-date">{moment(props.dateCensored, 'x').format("MMM D, YYYY")}</div>
                </>
                :
                null
            }
            {props.description? 
                <>
                    <p className="view-post-sub-title">Description:</p>
                    <p className="view-post-description">{props.description}</p>
                </>
                :
                null
            }
            {loggedIn.admin || loggedIn.moderator ?
                <button id="view-post-edit-button" onClick={goEditPost}>{props.public ? `Edit this post` : `Edit/publish this post`}</button>
                :
                null
            }
        </React.Fragment>
    )
}


const ViewPost = (props) => {
    const [ shareModalVisible, setShareModalVisible ] = useState(false);
    useEffect(() => {
        ReactGA.event({
            category: 'Posts',
            action: `Viewed post: ${props._id}`,
            label: `${props.title}`
        });
    }, [props])
    const domain = localStorage.getItem('domain');
    return (
        <React.Fragment>
            <Modal setModalVisible={props.setModalVisible} maxWidth='600px' content={<ViewPostContent setShareModalVisible={setShareModalVisible} shareable={true} {...props} />} />
            {shareModalVisible?
                <Share url={`${domain}/posts/${props._id}`} setShareModal={setShareModalVisible} {...props} />
                :
                null
            }
        </React.Fragment>
    );
}

export default ViewPost;