import React from 'react';
import { useField } from 'formik';

const DateInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label className="form-label" htmlFor={props.id || props.name}>{label}</label>
            <input className="form-input" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="form-error">{meta.error}</div>
            ) : null}
        </>
    )
}

const FileInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label className="form-label" htmlFor={props.id || props.name}>{label}</label>
            <input className="form-input file-input" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="form-error">{meta.error}</div>
            ) : null}
        </>
    )
}

const NumberInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label className="form-label" htmlFor={props.id || props.name}>{label}</label>
            <input className="form-input" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="form-error">{meta.error}</div>
            ) : null}
        </>
    )
}

const SelectInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label className="form-label" htmlFor={props.id || props.name}>{label}</label>
            <select className="form-input" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="form-error">{meta.error}</div>
            ) : null}
        </>
    )
}

const FilterInput = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
        <>
            <select {...field} {...props} />
        </>
    )
}

const CheckboxInput = ({ children, ...props }) => {
    const [field, meta] = useField(props, 'checkbox');
    return (
        <>
            <label className="form-label" style={props.style}>
                <input type="checkbox" {...field} {...props} />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="form-error">{meta.error}</div>
            ) : null}
        </>
    )
}

const TextAreaInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label className="form-label" htmlFor={props.id || props.name}>{label}</label>
            <textarea className="form-textarea" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="form-error">{meta.error}</div>
            ) : null}
        </>
    )
}

const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <label className="form-label" htmlFor={props.id || props.name}>{label}</label>
            <input className="form-input" {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="form-error">{meta.error}</div>
            ) : null}
        </>
    )
}

export {
    TextInput,
    TextAreaInput,
    CheckboxInput,
    SelectInput,
    NumberInput,
    FileInput,
    DateInput,
    FilterInput
}