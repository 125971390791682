import React, { useState, useEffect } from 'react';

import './ViewTips.css';

const ViewTips = props => {
    const [ tips, setTips ] = useState([]);
    const [ allTips, setAllTips ] = useState([]);
    const [ showReadTips, setShowReadTips ] = useState(false);

    const alterTips = (tipArr, showReadTips) => {
        let tipEls = [];
        tipArr.forEach(tip => tip.hidden && !showReadTips? null : tipEls.push(<div key={tip._id} className="tip">{tip.message}{tip.hidden? null : <div className="remove-tip-button" onClick={() => removeTip(tip._id)}>Mark as Read</div>}</div>))
        setTips(tipEls.reverse());
    }
    useEffect(() => {
        const api = localStorage.getItem('api');
        const token = sessionStorage.getItem('token');
        fetch(`${api}/tips/all`, {
            headers: {
                Authorization: token
            }
        })
        .then(res => {
            if(res.status !== 200){
                console.error(res);
                return;
            }
            return res.json();
        })
        .then(body => {
            alterTips(body);
            setAllTips(body);
        })
        .catch(err => console.error(err));
    }, [])

    const removeTip = _id => {
        const api = localStorage.getItem('api');
        const token = sessionStorage.getItem('token');
        fetch(`${api}/tips/delete-tip/${_id}`, {
            method: 'DELETE',
            headers: {
                Authorization: token
            }
        })
        .then(res => {
            if(res.status !== 200){
                console.error('Fail')
                return;
            }
            console.log(res)
        })
        .catch(error => console.error(error))
        setTimeout(() => {
            fetch(`${api}/tips/all`, {
                headers: {
                    Authorization: token
                }
            })
            .then(res => {
                if(res.status !== 200){
                    console.error(res);
                    return;
                }
                return res.json();
            })
            .then(body => {
                alterTips(body);
                setAllTips(body);
            })
            .catch(err => console.error(err));
        }, 500);
    }

    const toggleShowReadTips = () => {
        alterTips(allTips, !showReadTips);
        setShowReadTips(!showReadTips);
    }

    return ( 
        <div id='tips-container'>
            <button className="form-button toggle-show-read-tips" onClick={toggleShowReadTips}>{showReadTips? 'Hide' : 'Show'} tips marked as "Read"</button>
            {tips.length > 0?
                tips
                :
                <div style={{fontSize: '50px', fontWeight: '300', textAlign: 'center', padding: '10px', paddingTop: '25vh'}}>Currently no tips to show</div>
            }
        </div> 
    );
}
 
export default ViewTips;