import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReactGA from 'react-ga';

import { TextInput } from '../../FormComponents/FormComponents';
import Loading from '../../Loading/Loading';

const Login = (props) => {
    return (
        <div style={props.style}>
            <Formik 
                initialValues={{
                    identity: '',
                    password: ''
                }}
                validationSchema={Yup.object({
                    identity: Yup.string()
                        .required('Required'),
                    password: Yup.string()
                        .required('Required')
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    const api = localStorage.getItem('api');
                    const { identity, password } = values;
                    setSubmitting(true);
                    fetch(`${api}/user/login`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            identity,
                            password
                        })
                    })
                    .then(response => {
                        if(response.status === 200){
                            ReactGA.event({
                                category: 'Account',
                                action: 'Successful Login'
                            });
                            return response.json()
                        }
                        setSubmitting(false);
                        ReactGA.event({
                            category: 'Account',
                            action: 'Failed Login'
                        });
                        alert('faulty credentials')
                        return false;
                    })
                    .then(body => {
                        if(body){
                            sessionStorage.setItem('token', body.token);
                            sessionStorage.setItem('loggedIn', JSON.stringify({...body}));
                            setSubmitting(false);
                            window.location.pathname = '/logged-in'
                        }
                    })
                    .catch(error => alert(error));
                }}
            >
            {props => (
                <Form>
                    <h1 className="form-title">Login</h1>
                    <TextInput label="Username or Email" name="identity" type="text" />
                    <TextInput label="Password" name="password" type="password" />
                    <button className="form-button" type="submit">{props.isSubmitting? <Loading fontSize="20px" /> : 'Submit'}</button>
                </Form>
            )}
            </Formik>
            <div className="form-toggle" style={{color: 'var(--linkColor)'}} onClick={props.switchToPasswordRecover}>Forgot your password? Click here</div>
            <div className="form-toggle" onClick={props.switchToSignup}>Want to create an account? Click here</div>
        </div>
    )
}


export default Login;